/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';

.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}


html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  @extend .lato-regular;
  background-image: url("./assets/background.png");
  background-size: cover;
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
 }


#main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 5rem;
  // background-color: lightcyan;
}


.page-container {
  display: flex;
  align-self: center;
  flex-direction: column;
  flex-grow: 1;
  min-width: 720px;
  // background-color: lightsalmon;
}

.content {
  // background-color: green;
  margin: 1rem;
  align-items: center;
}

.page-content {
  // background-color: lightgray;
  // align-self: center;
  // margin: 1rem;
  // min-width: 720px;
}

.page-header {
  
  // padding: 0 0 0.5rem 0;
  // background-color:lightsalmon;
  // width: 100%;

}

.page-title {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.8px;
  text-align: center;
  padding: 1rem 0;
}




router-outlet {
  // display: flex;
  // background-color: red;
  // min-height: 100%;
}

.active {
  color: red !important;
}
